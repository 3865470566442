import React from 'react'
import { formatPhone } from '../../services/stringHandless'
import { CONTACT, ENDERECO, FACEBOOK, INSTAGRAM, LINK_ENDERECO_MAPS, NAME_CONTACT } from '../../config/flavor'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import WhatsappIcon from '../../assets/icons/whatsapp'
import FacebookIcon from '../../assets/icons/facebook'
import InstagramIcon from '../../assets/icons/instagram'
const Footer = () => {
	const handleLink = (link) => {
		if (!link) {
			return toast.error('Link não informado')
		}
		window.open(link, '_blank').focus()
	}
	const handleWhatsapp = () => {
		const message = `Olá ${NAME_CONTACT} tudo bem? \n Poderia me ajudar?`
		window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus()
	}
	return (
		<div className="container-fluid bg-light">
			<footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
				<div className="col-md-4 col-sm-6 mb-3">
					<h5 style={{ color: '#a88e76' }}>Enderço(s)</h5>
					<ul className="nav flex-column">
						<li className="nav-item mb-2">
							<Link to={'#'} onClick={() => handleLink(LINK_ENDERECO_MAPS)} className="nav-link p-0 text-muted">
								{ENDERECO}
							</Link>
						</li>
					</ul>
				</div>
				<div className="col-md-4 col-sm-6 mb-3">
					<h5 style={{ color: '#a88e76' }}>Telefone(s)</h5>
					<ul className="nav flex-column">
						<li className="nav-item mb-2">
							<Link to="#" onClick={handleWhatsapp} className="nav-link p-0 text-muted">
								<WhatsappIcon color={'#333'} />
								{formatPhone(CONTACT)}
							</Link>
						</li>
					</ul>
				</div>
				<div className="col-md-4 col-sm-12 mb-3">
					<h5 style={{ color: '#a88e76' }}>Redes sociais</h5>
					<ul className="nav flex-row">
						<li className="nav-item mb-2">
							<Link onClick={() => handleLink(FACEBOOK)} to="#" className="nav-link p-0 text-muted">
								<FacebookIcon color={'#333'} />
							</Link>
						</li>
						<li className="nav-item mb-2">
							<Link onClick={() => handleLink(INSTAGRAM)} to="#" className="nav-link p-0 text-muted">
								<InstagramIcon color={'#333'} />
							</Link>
						</li>
					</ul>
				</div>
				<div className='col-md-12 col-sm-12 text-center'>
					<Link href="/" className="mb-3 link-dark text-decoration-none">
						<span className="text-muted">RSL</span>© 2024 <span className="text-muted">Todos os direitos reservados</span>
					</Link>
				</div>
			</footer>
		</div>
	)
}
export default Footer