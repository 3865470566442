import React, { useRef } from 'react'
import FormContact from '../../components/forms/contact'
import { CONTACT, ENDERECO, LINK_ENDERECO_MAPS, NAME_CONTACT } from '../../config/flavor'
import WhatsappIcon from '../../assets/icons/whatsapp'
import { formatPhone } from '../../services/stringHandless'
import { Link } from 'react-router-dom'
const Contact = () => {
	const handleWhatsapp = () => {
		const message = `Olá ${NAME_CONTACT} tudo bem? \n Poderia me ajudar?`
		window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus()
	}
	const ref = useRef(null)
	const handleMaps = () => {
		window.open(`${LINK_ENDERECO_MAPS}`, 'blank').focus()
	}
	return (
		<div className='container-fluid mt-5'>
			<div className="card">
				<div className="card-body text-center">
					<h1 className="card-title mb-3 fs-1" style={{ color: '#a28a6b' }}>Contato</h1>
				</div>
				<div className='container'>
					<div className='card border-0 mb-3' style={{ backgroundColor: 'transparent' }}>
						<div className='row'>
							<div className='col-md-8 col-sm-12'>
								<div className='card-body'>
									<FormContact />
								</div>
							</div>
							<div className='col-md-4 col-sm-12' ref={ref}>
								<div className='card-body'>
									<div className="col-12 mb-3">
										<h5 style={{ color: '#a88e76' }}>Enderço(s)</h5>
										<Link to={'#'} className='text-decoration-none text-dark' title='Ver no mapa' onClick={handleMaps}>{ENDERECO}</Link>
									</div>
									<div className="col-12 mb-3">
										<h5 style={{ color: '#a88e76' }}>Telefone(s)</h5>
										<Link to={'#'} onClick={handleWhatsapp}
											className='text-decoration-none text-dark'><WhatsappIcon color={'#333'} />{formatPhone(CONTACT)}</Link>
									</div>
									<div className='col-12 w-100' style={{
										width: '100%',
										height: '62vh', /* ou qualquer altura desejada para a div */
										backgroundImage: 'url(\'https://img77.uenicdn.com/image/upload/v1554290143/service_images/shutterstock_528555109.jpg\')', /* substitua pelo caminho da sua imagem */
										backgroundSize: 'cover', /* ajuste o tamanho da imagem */
										backgroundRepeat: 'no-repeat', /* certifique-se de que a imagem não se repita */
										backgroundPosition: 'center bottom', /* posição da imagem - no centro e no rodapé */
										position: 'relative', /* para que a posição da imagem seja relativa à div */
									}}>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}
export default Contact