import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import { allCategory } from '../../../constants'
import PlantIcon from '../../../assets/icons/plant'
import { getElementPosition } from '../../../services/getPropsElNode'
const NavLink = ({ items, onClick, activeLink, setActiveLink, flex, useRef }) => {
	const handleClick = (item) => {
		if (!onClick) {
			return
		}
		if (item.value !== activeLink && setActiveLink) {
			setActiveLink(item.value)
		}
		if (useRef) {
			const {top, left} = getElementPosition(useRef)
			window.scrollTo(top,left)
		}
		onClick(item)
	}
	if (flex === 'flex-column') {
		return (
			<ul className="mb-3 nav justify-content-start bg-success flex-column flex-nowrap h-100" style={{
				overflow: 'hidden',
			}}>
				<li className="nav-item me-3 w-100" style={{ backgroundColor: activeLink === allCategory.value ? '#a28a6b' : 'transparent' }} >
					<Link to={'#'} onClick={() => handleClick(allCategory)}
						className="text-capitalize nav-link text-light text-nowrap">
						{allCategory.value} {activeLink === allCategory.value && (<PlantIcon />)}
					</Link>
				</li>
				{Array.isArray(items) && items.map((item, i) => (
					item.value === activeLink ? (
						<li className="nav-item me-3 text-nowrap  w-100" style={{ backgroundColor: '#a28a6b' }} key={i}>
							<Link to={'#'} onClick={() => handleClick(item)} className="text-capitalize nav-link text-light">
								{item.value} <PlantIcon />
							</Link>
						</li>
					) : (
						<li className="nav-item me-3 text-nowrap  w-100" key={i}>
							<Link to={'#'} onClick={() => handleClick(item)} className="text-capitalize nav-link text-light">
								{item.value}
							</Link>
						</li>
					)
				))}
			</ul>
		)
	}
	return (
		<ul className="mb-3 nav justify-content-start bg-success flex-nowrap sticky-top" style={{
			overflowX: 'scroll',
		}}>
			<li className="nav-item me-3  w-100" style={{ backgroundColor: activeLink === allCategory.value ? '#a28a6b' : 'transparent' }} >
				<Link to={'#'} onClick={() => handleClick(allCategory)}
					className="text-capitalize nav-link text-light text-nowrap">
					{allCategory.value} {activeLink === allCategory.value && (<PlantIcon />)}
				</Link>
			</li>
			{Array.isArray(items) && items.map((item, i) => (
				item.value === activeLink ? (
					<li className="nav-item me-3 text-nowrap  w-100" style={{ backgroundColor: '#a28a6b' }} key={i}>
						<Link to={'#'} onClick={() => handleClick(item)} className="text-capitalize nav-link text-light">
							{item.value} <PlantIcon />
						</Link>
					</li>
				) : (
					<li className="nav-item me-3 text-nowrap  w-100" key={i}>
						<Link to={'#'} onClick={() => handleClick(item)} className="text-capitalize nav-link text-light">
							{item.value}
						</Link>
					</li>
				)
			))}
		</ul>
	)
}
export default NavLink