import { LOCAL_URL } from "./local-config";
import img_background from "../assets/RSL/images/img_background.jpeg";
import img_background_1 from "../assets/RSL/images/TOPO-EQUIPAMENTO.jpg";
import about_top_page from "../assets/RSL/images/about_top_page.jpeg";
import contact_top_page from "../assets/RSL/images/contact_top_page.webp";
import logo from "../assets/RSL/images/logo.jpeg";
import Package from "../../package.json";
export const FLAVOR = "Artesep SGI-DIN";
export const URL_TESTE = "";
export const URL_HOMOL = "";
export const URL_PRODUCTION = "";
export const URL_DEVELOP = LOCAL_URL;
export const IMG_BACKGROUND = img_background;
export const IMG_BACKGROUND_1 = img_background_1;
export const ABOUT_TOP_PAGE = about_top_page;
export const CONTACT_TOP_PAGE = contact_top_page;
export const LOGO = logo;
export const VERSION = Package.version;
export const OFFLINE_POST = true;
export const CONTACT = "(14)2024-0673";
export const NAME_CONTACT = "Anali Moraes";
export const URL_FILE = "https://RSL-production.s3.amazonaws.com";
export const URL_FILE_TESTE = "https://RSL-test.s3.amazonaws.com";
export const URL_FILE_DEVELOP = `${LOCAL_URL}/storage`;
export const ENDERECO = "Praça Rui Barbosa n° 75, Sala I-9 1°andar";
export const LINK_ENDERECO_MAPS =
  "https://www.google.com/maps/search/Pra%C3%A7a+Rui+Barbosa+n%C2%B0+75,+Sala+I-9+1%C2%B0andar/@-25.4355412,-49.2755838,17z/data=!3m1!4b1?entry=ttu";
export const FACEBOOK = "https://facebook.com";
export const INSTAGRAM = "https://instagram.com";
export const YOUTUBE = "https://youtube.com";
