import React, { createContext, useState } from 'react'
import { execptionWithIcons, headerOptions } from '../../webservice'
import { useNavigate } from 'react-router-dom'
import { statusCode } from '../../constants'
export const requestContext = createContext()
const RequestContext = ({ children }) => {
	const [data, setData] = useState(null)
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	const [infoRequest, setInfoRequest] = useState({
		status: statusCode.successfull,
		message: '',
		color: '',
	})
	const postItemEmail = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions(
			'/',
			'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/')
		}
	}
	return (
		<requestContext.Provider
			value={{
				data,
				infoRequest,
				setInfoRequest,
				setData,
				postItemEmail,
				loading
			}}
		>
			{children}
		</requestContext.Provider>
	)
}
export default RequestContext