export const modalShade = {
	position: 'fixed',
	display: 'flex',
	direction: 'row',
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	zIndex: '10000',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'rgba(0,0,0,0.4)',
}

export const modalBody = (modalWidth, modalHeight) => {
	return(
		{
			width:modalWidth==='auto'?'auto':`${modalWidth}%`,
			height:modalHeight==='auto'?'auto':`${modalHeight}%`,
			minWidth:'300px',
			backgroundColor: 'white',
			padding:'1rem',
			borderRadius:'1rem'
		}
	)

}

export const modalHeaderTitle = {
	fontSize: '1.5rem',
	fontWeight:'bold'
}

export const modalHeader = {
	width:'100%',
	display:'flex',
	justifyContent:'space-between'
    
}

export const modalClose = {
	width:'2rem',
	height:'2rem',
	padding:0,
	borderRadius:'1rem',
	backgroundColor: 'transparent',
	border:'1px solid grey',
	color:'grey',
	fontSize:'0.7rem',
	fontFamily:'sans-serif'
    
}