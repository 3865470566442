const getPropsDimissions = (elId) => {
	let nodes = []
	if (Array.isArray(elId)) {
		for (let i = 0; i < elId.length; i++) {
			const el = document.querySelector(`#${elId}`)
			const props = el.getBoundingClientRect()
			const {
				width, height,
			} = props
			nodes.push({
				width, height,
				left: props.left + window.scrollX,
				top: props.top + window.scrollY
			})
		}
		const sum_height = nodes.reduce((a, r) => a + r.height, 0)
		const sum_width = nodes.reduce((a, r) => a + r.width, 0)
		return {
			width: Math.trunc(sum_width),
			height: Math.trunc(sum_height)
		}
	}
	const el = document.querySelector(`#${elId}`)
	const props = el.getBoundingClientRect()
	const {
		width, height
	} = props

	return {
		width, height, left: props.left + window.scrollX,
		top: props.top + window.scrollY
	}

}
/**
 * Get the position and dimensions of a DOM element referenced by a ref.
 * @param {React.RefObject<HTMLElement>} divRef - The ref object referencing the DOM element.
 * @returns {Object} An object containing the position and dimensions of the element.
 */
function getElementPosition(divRef) {
	/**
	 * @typedef {Object} ElementPosition
	 * @property {number} top - The distance from the top of the viewport to the top edge of the element.
	 * @property {number} left - The distance from the left of the viewport to the left edge of the element.
	 * @property {number} bottom - The distance from the top of the viewport to the bottom edge of the element.
	 * @property {number} right - The distance from the left of the viewport to the right edge of the element.
	 * @property {number} width - The width of the element.
	 * @property {number} height - The height of the element.
	 */

	// Get the DOM element using the ref
	const element = divRef.current

	// Return the position and dimensions using getBoundingClientRect()
	return /** @type {ElementPosition} */ ({
		top: element.getBoundingClientRect().top,
		left: element.getBoundingClientRect().left,
		bottom: element.getBoundingClientRect().bottom,
		right: element.getBoundingClientRect().right,
		width: element.getBoundingClientRect().width,
		height: element.getBoundingClientRect().height
	})
}
export { getElementPosition, getPropsDimissions }