
export const statusCode = {
	successfull: 200,
	bad_request: 400,
	forbidden: 401,
	internal_server_error: 500
}
export const qtd_products_home_page = 3
export const allCategory = {
	product_name: 'Todas as categorias',
	description: '',
	image_name: '',
	hfref: '',
	is_emphasis: false,
	price: 0.00,
	category: 'Todas as Categorias',
	value: 'Todas as Categorias'
}
