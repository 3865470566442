import React from 'react'
import { ListProducts } from '../../components/list/products'
import JumBotronAbout from '../../components/jumbotron'
import FormContact from '../../components/forms/contact'
import products_items from '../../components/list/products/products_items.json'
const Home = () => {
	return (
		<div className='container-fluid mt-5'>
			<div className='container mb-5'>
				<div className="border-0 card text-center w-100 mb-5">
					<div className="card-body">
						<h1 style={{ color: '#a88e76' }} className="card-title mb-3 fs-1">Produtos</h1>
						<p className='card-text fst-italic'>Uma breve descrição sobre os produtos</p>
					</div>
					<div className='card border-0 text-center mb-4' id='products'>
						<div className='card-body'>
							<div className='row'>
								<ListProducts items={products_items} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container mb-5'>
				<div className='card'>
					<div className='row'>
						<div className='col-md-4 d-none d-md-block'>
							<img src={require('../../assets/RSL/images/about_home_page.jpg')}
								style={{ width: '100%', height: '100%' }}
								className="card-img-top" />
						</div>
						<div className='col-md-8 col-sm-12'>
							<div className="card-body">
								<div className="border-0 card text-center w-100">
									<h1 style={{ color: '#a88e76' }} className="card-title mb-3 fs-1">Sobre nós</h1>
								</div>
							</div>
							<JumBotronAbout />
						</div>
					</div>
				</div>
			</div>
			<div className='container mb-5'>
				<div className="card border-0 text-center">
					<div className='row'>
						<div className='col-md-8 col-sm-12'>
							<div className='card-body'>
								<h1 style={{ color: '#a28a6b' }} className="card-title mb-3 fs-1">Contato</h1>
								<FormContact />
							</div>
						</div>
						<div className='col-md-4 d-none d-md-block'>
							<img src={require('../../assets/RSL/images/contact_home_page.jpg')}
								style={{ width: '100%', height: '100%' }}
								className="card-img-top" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Home