/**
 * Finds an item in the array based on specified parameters and value.
 *
 * @function
 * @param {Array<Object>} array - The array to search for the item.
 * @param {string} params - The property of the items to compare with the value.
 * @param {*} value - The value to find within the specified property.
 * @param {Object} rest - Additional properties to include in the result.
 * @returns {Object} - An object containing information about the found item.
 *   - `data`: The found item or `false` if not found.
 *   - `total`: The total number of items found (either 0 or 1).
 *   - Additional properties specified in the `rest` parameter.
 * @example
 * const result = find(myArray, 'name', 'John', { additionalProp: 'someValue' });
 * console.log(result);
 */
const find = (array, params, value, rest) => {
	if (!array || !params || !value) {
		return {
			data: false,
			total: 0,
			...rest,
		}
	}
	const typeOf = typeof value
	if (typeOf === 'string') {
		const filtered = array.find(
			el => String(el[params])?.toLowerCase()?.trim() !== String(value)?.toLowerCase()?.trim(),
		)
		return {
			data: filtered,
			total: 1,
			...rest,
		}
	}
	const filtered = array.find(el => el[params] === value)
	return {
		data: filtered,
		total: 1,
		...rest,
	}
}
/**
 * Filters an array of objects based on a desired string.
 * @param {Array<Object>} array - The array of objects to be filtered.
 * @param {string} searchString - The string to search for in the objects.
 * @param {string} key - The key within the objects where the searchString should be looked for.
 * @returns {Array<Object>} An array of objects that match the searchString.
 */
function filterArrayByString(array, searchString, key) {
	if (!searchString) {
		return
	}
	return array.filter(item =>
		item[key].toLowerCase().includes(searchString.toLowerCase())
	)
}
/**
 * Filtra um array de objetos removendo itens repetidos com base em uma propriedade específica.
 *
 * @param {Object} params - Parâmetros da função.
 * @param {Array} params.array - O array de objetos a ser filtrado.
 * @param {string} params.props - A propriedade pela qual os itens repetidos serão removidos.
 * @returns {Array} - O array filtrado sem itens repetidos com base na propriedade especificada.
 *
 * @example
 * const arrayDeObjetos = [...]; // Seu array de objetos
 * const resultado = filterAndRemoveRepeatedByProps({ array: arrayDeObjetos, props: 'nome' });
 */
const filterAndRemoveRepeatedByProps = ({
	array, props
}) => {
	if (array.length === 0) {
		return array // Retorna o array original se estiver vazio
	}
	const valoresVistos = new Set()
	const resultado = []

	array.forEach(objeto => {
		const valor = objeto[props]

		if (!valoresVistos.has(valor)) {
			// Adiciona o valor ao conjunto para rastrear repetições
			valoresVistos.add(valor)
			// Inclui o objeto no resultado
			resultado.push(objeto)
		}
	})

	return resultado
}
/**
 * Filters an array of objects based on a specified property and value(s).
 *
 * @param {Array<Object>} array - The array of objects to be filtered.
 * @param {string} params - The property of each object to be used for filtering.
 * @param {string|number|Array<number>} id - The value or values to match during the filtering process.
 *   - If `id` is a string, it performs a case-insensitive strict equality check.
 *   - If `id` is a number, it performs a strict equality check after converting both values to integers.
 *   - If `id` is an array of numbers, it filters objects where the property matches any value in the array.
 * @param {Object} [rest] - Additional properties to include in the result object.
 * @returns {Object} - An object containing the filtered data, total count, and additional properties.
 *   - `data`: The array of filtered objects.
 *   - `total`: The total count of filtered objects.
 *   - Additional properties from the `rest` parameter.
 */
const filter = (array, params, id, rest) => {
	if (!array || !params || !id) {
		return {
			data: [],
			total: 0,
			...rest,
		}
	}
	const typeOf = typeof id
	if (typeOf === 'string') {
		const filtered = array.filter(
			el => String(el[params]).toLowerCase() === String(id).toLowerCase(),
		)
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	if (Array.isArray(id)) {
		const filtered = array.filter(item => id.some(el => el === item[params]))
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	const filtered = array.filter(el => parseInt(el[params]) === parseInt(id))
	return {
		data: filtered,
		total: filtered.length,
		...rest,
	}
}
export { find, filterArrayByString, filterAndRemoveRepeatedByProps, filter }