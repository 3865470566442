import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { formatPhone } from '../../../services/stringHandless'
import { isValid } from '../../../services/isValid'
import { toast } from 'react-toastify'
import { getPropsDimissions } from '../../../services/getPropsElNode'
const FormContact = () => {
	const { onChange, onChangePhone, state, handleForm } = useContext(formContext)
	const {
		email,
		name,
		tel,
		city,
		uf,
		msg
	} = state
	useEffect(() => {
		const propsnode = getPropsDimissions('props_node_contact')
		window.localStorage.setItem('props_node_contact', JSON.stringify(propsnode))
	}, [])
	const handleCancel = () => {
		handleForm({
			email: '',
			name: '',
			tel: '',
			city: '',
			uf: '',
			msg: ''
		})
	}
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const valid = isValid({
			email,
			name,
			tel,
			city,
			uf,
			msg
		})
		if (!valid) {
			return toast.error('Preencha todos os campos!')
		}
		handleCancel()
		window.scrollTo(0, 0)
		return toast.success('Email enviado com sucesso!')
	}
	return (
		<form onSubmit={onSubmit} id="props_node_contact">
			<div className="row">
				<div className="mb-3 form-group col-md-6 col-sm-12">
					<label>Seu nome:</label>
					<div className="input-group">
						<input type="text" onChange={onChange}
							className={`form-control form-control-border ${!name && 'is-invalid'}`}
							name='name' value={name}
							placeholder='Digite aqui seu nome' />
					</div>
				</div>
				<div className="mb-3 form-group col-md-6 col-sm-12">
					<label>Seu email:</label>
					<div className="input-group">
						<input type="text" onChange={onChange}
							className={`form-control form-control-border ${!email && 'is-invalid'}`}
							name='email' value={email}
							placeholder='Digite aqui seu e-mail para enviarmos nossa resposta' />
					</div>
				</div>
				<div className="mb-3 form-group col-md-6 col-sm-12">
					<label>Seu telefone/Whatsapp:</label>
					<div className="input-group">
						<input type="text" onChange={onChangePhone}
							className={`form-control form-control-border ${!tel && 'is-invalid'}`}
							name='tel' value={tel}
							placeholder={formatPhone('00000000000')} />
					</div>
				</div>
				<div className="mb-3 form-group col-md-8 col-sm-12">
					<label>Sua cidade:</label>
					<div className="input-group">
						<input type="text" onChange={onChange}
							className={`form-control form-control-border ${!city && 'is-invalid'}`}
							name='city' value={city}
							placeholder='Digite aqui sua cidade' />
					</div>
				</div>
				<div className="mb-3 form-group col-md-4 col-sm-12">
					<label>Seu Estado:</label>
					<div className="input-group">
						<input type="text" onChange={onChange}
							className={`form-control form-control-border ${!uf && 'is-invalid'}`}
							name='uf' value={uf}
							placeholder='UF' />
					</div>
				</div>
				<div className="form-group col-12">
					<label>Sua mensagem:</label>
					<div className="input-group">
						<textarea
							type="text"
							onChange={onChange}
							rows={5}
							name="msg"
							value={msg}
							className={`form-control form-control-border ${!msg && 'is-invalid'
							}`}
							placeholder="Descreva brevemente o que é esse anexo"
						>
							{msg}
						</textarea>
					</div>
				</div>
				<div className='col-12 mt-3'>
					<button type="submit" className="btn btn-success me-3">
						Enviar <i className="fa fa-paper-plane"></i>
					</button>
					<div className="btn btn-secondary" onClick={() => handleCancel()}>
						Cancelar <i className="fa fa-times-circle"></i>
					</div>
				</div>
			</div>
		</form>
	)
}
export default FormContact