import React from "react";
import { formatPhone } from "../../services/stringHandless";
import {
  CONTACT,
  ENDERECO,
  FACEBOOK,
  INSTAGRAM,
  LINK_ENDERECO_MAPS,
  NAME_CONTACT,
} from "../../config/flavor";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const WhatsAppIconCall = () => {
  const openWhatsApp = () => {
    const phoneNumber = "14981979410";
    const message =
      "Olá, estou interessado(a) nos tubetes e bandejas para plantas que vocês vendem. Poderiam me passar mais informações, por favor?!";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message,
    )}`;

    window.open(url, "_blank");
  };

  return (
    <div style={styles.floatingButton} onClick={openWhatsApp}>
      <img
        src='https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg'
        alt='WhatsApp'
        style={styles.whatsappIcon}
      />
    </div>
  );
};

const styles = {
  floatingButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "60px",
    height: "60px",
    backgroundColor: "#25D366",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    zIndex: 1000,
  },
  whatsappIcon: {
    width: "30px",
    height: "30px",
  },
};
export default WhatsAppIconCall;
