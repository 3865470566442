import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
/**
 * Scrolls the page to a specific target element smoothly.
 * @function scrollToTarget
 */
const scrollToTarget = (targetRef) => {
	if (!targetRef) {
		return
	}
	window.scroll(targetRef.top, targetRef.left)
}

/**
 * Scroll to the top of the window whenever the pathname changes.
 */
export default function ScrollToTop() {
	/**
   * Scrolls to the top of the window whenever the pathname changes.
   * @returns {null} Returns null.
   */
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

/**
 * Focuses on the given element and scrolls to its position.
 * @param {HTMLElement} elem - The element to focus on.
 * @returns {void}
 */
export const cursorFocus = function (elem) {
	const x = elem.offsetTop
	const y = elem.offsetLeft
	elem.focus()
	window.scrollTo(x, y)
}

export { scrollToTarget }  