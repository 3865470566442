import React from "react";
import { Masthead } from "../../components/masthead";
import { Navigation } from "../../components/navigation/idnex";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../../views/home";
import About from "../../views/about";
import Products from "../../views/products";
import ScrollToTop from "../../services/scrollTo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "../../views/contact";
import Footer from "../../components/footer";
import WhatsAppIconCall from "../../components/whatsappicon";
const AppRouter = () => {
  const location = useLocation();

  return (
    <>
      <WhatsAppIconCall />
      <Navigation />
      <ToastContainer />
      <Masthead pathname={location?.pathname} />
      <ScrollToTop />

      <Routes>
        <Route index element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/products' element={<Products />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};
export default AppRouter;
