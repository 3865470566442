import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  convertToTitleCase,
  formatCurrency,
} from "../../../services/stringHandless";
import { qtd_products_home_page } from "../../../constants";
import WhatsappIcon from "../../../assets/icons/whatsapp";
import { CONTACT, NAME_CONTACT } from "../../../config/flavor";
import { getPropsDimissions } from "../../../services/getPropsElNode";
import SimpleModal from "../../modal/simple";

export const ListProducts = ({ items }) => {
  const [show, setShow] = useState(false);
  const [modalProperties, setModalProperties] = useState({
    modalWidth: 50,
    modalHeight: "auto",
    modalTitle: "Titulo da modal",
    modalChild: "",
  });
  useEffect(() => {
    const propsnode = getPropsDimissions("props_node_product");
    window.localStorage.setItem(
      "props_node_product",
      JSON.stringify(propsnode),
    );
  }, []);
  const location = useLocation();
  if (!Array.isArray(items)) {
    return (
      <div className='text-secondary'>Não há itens para serem exibidos</div>
    );
  }
  const handleWhatsapp = (description) => {
    const message = `Olá ${NAME_CONTACT} tudo bem? \n Eu me interessei por esse produto: ${description}`;
    window
      .open(
        `https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`,
        "blank",
      )
      .focus();
  };
  function openImageInNewTab(imageUrl) {
    var link = document.createElement("a");
    link.href = imageUrl;
    link.target = "_blank";
    link.click();
  }
  const ModalChild = ({ item }) => {
    return (
      <div className='card m-4'>
        <div className='row align-items-center'>
          <div className='col-md-4 col-sm-12'>
            <img
              onClick={() =>
                openImageInNewTab(
                  require(`../../../assets/RSL/images/products/${item.image_name}`),
                )
              }
              src={require(`../../../assets/RSL/images/products/${item.image_name}`)}
              style={{ height: "auto", width: "100%" }}
              className='card-img-top'
              alt={convertToTitleCase(item.product_name)}
            />
          </div>
          <div className='col-md-8 col-sm-12'>
            <div className='card-body'>
              <h5 className='card-title' style={{ color: "#a28a6b" }}>
                {convertToTitleCase(item.product_name)}
              </h5>
              <p className='text-capitalize card-text'>{item.description}</p>
              <p className='card-text'>
                <span className='fw-bold' style={{ color: "#a28a6b" }}>
                  Categoria:{" "}
                </span>{" "}
                {item.category}
              </p>
              <Link
                to='#'
                onClick={() => handleWhatsapp(item.product_name)}
                className='btn btn-success'
              >
                <WhatsappIcon />
                Chamar vendedor
              </Link>
            </div>
            {/* <div className="card-footer text-body-secondary" style={{ backgroundColor: 'transparent' }}>
							{formatCurrency(item.price)}
						</div> */}
          </div>
        </div>
      </div>
    );
  };
  // MUDAR TAMANHO DA IMAGEM MODAL
  const onClick = (item) => {
    setShow(true);
    setModalProperties({
      ...modalProperties,
      modalTitle: item?.product_name,
      modalWidth: "auto",
      modalChild: <ModalChild item={item} />,
    });
  };
  return (
    <div className='row justify-content-center' id='props_node_product'>
      <SimpleModal
        visible={show}
        setVisible={setShow}
        properties={modalProperties}
      />
      {location?.pathname === "/" ? (
        <>
          {items
            .slice(
              0,
              qtd_products_home_page > 0
                ? qtd_products_home_page
                : items.length,
            )
            .filter((item) => item.is_emphasis)
            .map((item, i) => (
              <div
                className='card m-4'
                style={{ width: "18rem", cursor: "pointer" }}
                key={i}
              >
                <img
                  onClick={() =>
                    onClick({
                      ...item,
                      i: i,
                    })
                  }
                  src={require(`../../../assets/RSL/images/products/${item.image_name}`)}
                  style={{ height: "auto", width: "100%" }}
                  className='card-img-top'
                  alt={convertToTitleCase(item.product_name)}
                />
                <div className='card-body'>
                  <h5 className='card-title' style={{ color: "#a28a6b" }}>
                    {convertToTitleCase(item.product_name)}
                  </h5>
                  <p className='text-capitalize card-text'>
                    {item.description}
                  </p>
                  <p className='card-text'>
                    <span className='fw-bold' style={{ color: "#a28a6b" }}>
                      Categoria:{" "}
                    </span>{" "}
                    {item.category}
                  </p>
                  <Link
                    to='#'
                    onClick={() => handleWhatsapp(item.product_name)}
                    className='btn btn-success'
                  >
                    <WhatsappIcon />
                    Chamar vendedor
                  </Link>
                </div>
                {/* <div className="card-footer text-body-secondary" style={{ backgroundColor: 'transparent' }}>
								{formatCurrency(item.price)}
							</div> */}
              </div>
            ))}
          {qtd_products_home_page > 0 && (
            <div
              className='card w-100 mb-3 border-0 text-center'
              style={{ backgroundColor: "transparent" }}
            >
              <div className='card-body'>
                <Link to='/products' className='btn btn-success'>
                  Ver mais produtos
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        items.map((item, i) => (
          <div
            className='card m-4'
            style={{ width: "18rem", cursor: "pointer" }}
            key={i}
          >
            <img
              onClick={() =>
                onClick({
                  ...item,
                  i: i,
                })
              }
              src={require(`../../../assets/RSL/images/products/${item.image_name}`)}
              style={{ height: "auto" }}
              className='card-img-top w-100'
              alt={convertToTitleCase(item.product_name)}
            />
            <div className='card-body'>
              <h5 className='card-title' style={{ color: "#a28a6b" }}>
                {convertToTitleCase(item.product_name)}
              </h5>
              <p className='text-capitalize card-text'>{item.description}</p>
              <p className='card-text'>
                <span className='fw-bold' style={{ color: "#a28a6b" }}>
                  Categoria:{" "}
                </span>{" "}
                {item.category}
              </p>
              <Link
                to='#'
                onClick={() => handleWhatsapp(item.product_name)}
                className='btn btn-success'
              >
                <WhatsappIcon color={"#fff"} />
                Chamar vendedor
              </Link>
            </div>
            {/* <div className="card-footer text-body-secondary" style={{ backgroundColor: 'transparent' }}>
							{formatCurrency(item.price)}
						</div> */}
          </div>
        ))
      )}
    </div>
  );
};
