import React from 'react'
import JumBotronAbout from '../../components/jumbotron'
import { Link } from 'react-router-dom'
const About = () => {
	return (
		<div className='container-fluid mt-3'>
			<div className='container'>
				<div className="border-0 card text-center w-100" >
					<div className="card-body">
						<h1 className="card-title mb-3 fs-1" style={{ color: '#a28a6b' }}>Sobre nós</h1>
					</div>
					<JumBotronAbout />
					<div className='card-footer'style={{backgroundColor: 'transparent'}}>
						<Link to={'/'} className='btn btn-success'>
							Voltar para o Inicío
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
export default About