/**
 * Converts a string representing a numeric value to a formatted currency string in Brazilian Real (BRL).
 *
 * @function
 * @param {string} stringValue - The string representing the numeric value.
 * @param {boolean} show_tipe_coin - The string show type coin, for exemplo R$ when formatted currency string in Brazilian.
 * @returns {string} - The formatted currency string in Brazilian Real (BRL).
 * @throws {string} - return string empty when error.
 */
const formatCurrency = (stringValue, show_tipe_coin = true) => {
	/**
	 * Numeric value obtained by parsing the input string.
	 * @type {number}
	 */
	const numberValue = parseFloat(stringValue)

	if (isNaN(numberValue)) {
		return ''
	}

	/**
	 * Formatted currency string in Brazilian Real (BRL).
	 * @type {string}
	 */
	const formattedString = numberValue.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
	if (!show_tipe_coin) {
		return formattedString.replace('R$', '').trim()
	}
	return formattedString
}
/**
 * Converts a string to title case.
 * @param {string} value - The string to convert.
 * @returns {string} The string converted to title case.
 */
const convertToTitleCase = (value) => {
	return value.toLowerCase().replace(/\b\w/g, function (char) {
		return char.toUpperCase()
	})
}
/**
 * Formats a string as a phone number.
 * @param {string} string - The string to be formatted as a phone number.
 * @returns {string} The formatted phone number.
 */
const formatPhone = (string) => {
	if (!string) {
		return ''
	}
	var r = string.replace(/\D/g, '')
	r = r.replace(/^0/, '')
	if (r.length > 10) {
		r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
	} else if (r.length > 5) {
		r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
	} else if (r.length > 2) {
		r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
	}
	return r
}
export { formatCurrency, convertToTitleCase, formatPhone }
