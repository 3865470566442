import React, { useContext, useEffect, useState } from 'react'
import { ListProducts } from '../../components/list/products'
import Search from '../../components/forms/search'
import products_items from '../../components/list/products/products_items.json'
import { formContext } from '../../context/formContext'
import { filter, filterAndRemoveRepeatedByProps, filterArrayByString } from '../../services/arrayHandlers'
import NavLink from '../../components/nav/navLink'
import { allCategory } from '../../constants'

const Products = () => {
	const [items, setItems] = useState(products_items)
	const [dropDownItems, setDropDownItems] = useState(null)
	const [activeLink, setActiveLink] = useState(null)
	const { state: { product_name }, handleForm } = useContext(formContext)
	const flex = 'flex-column'
	const [ref, setRef] = useState(null)
	useEffect(() => {
		setActiveLink(allCategory.value)
		handleForm({
			category: '',
			product_name: '',
		})
	}, [])
	const filterProductsByCategory = () => {
		if (!activeLink) {
			return
		}
		if (activeLink !== allCategory.value) {
			const { data } = filter(products_items, 'category', activeLink)
			return setItems(data)
		}
		setItems(products_items)
	}
	const handleSarch = () => {
		if (!product_name) {
			return filterProductsByCategory()
		}
		if (!Array.isArray(items)) {
			return
		}
		const filteredItems = filterArrayByString(items, product_name, 'product_name')
		if (!activeLink) {
			return setItems(filteredItems)
		}
		if (activeLink !== allCategory.value) {
			const { data } = filter(filteredItems, 'category', activeLink)
			return setItems(data)
		}
		setItems(filteredItems)
	}
	useEffect(() => {
		filterProductsByCategory()
	}, [activeLink])
	useEffect(() => {
		const reloadDropDownItems = () => {
			const tempdropdownitems = products_items.map(item => ({
				...item,
				value: item.category
			}))
			const dropdownitems = filterAndRemoveRepeatedByProps({ array: tempdropdownitems, props: 'value' })
			setDropDownItems(dropdownitems)
		}
		reloadDropDownItems()
	}, [])
	useEffect(() => {
		const reloadItems = () => {
			if (!product_name) {
				filterProductsByCategory()
			}
		}
		reloadItems()
	}, [product_name])
	const onClick = (item) => {
		const { value, category } = item
		setActiveLink(value)
		if (category === allCategory.value) {
			return handleForm({
				category: '',
			})
		}
		handleForm({
			category,
		})
	}
	return (
		<div className='container mt-5'>
			<div className="border-0 card text-center w-100 mb-5">
				<div className="card-body">
					<h1 className="card-title mb-3 fs-1" style={{ color: '#a28a6b' }}>Produtos</h1>
					<p className='card-text fst-italic'>Uma breve descrição sobre os produtos</p>
					<div className='container-fluid text-center d-none d-md-block'>
						<Search handleSarch={handleSarch} />
						<div className='row'>
							<div className='col-md-3 col-sm-12'>
								<NavLink flex={flex} items={dropDownItems} activeLink={activeLink} setActiveLink={setActiveLink} onClick={onClick} />
							</div>
							<div className='col-md-9 col-sm-12'>
								<ListProducts items={items} />
							</div>
						</div>
					</div>
					<div className='container-fluid text-center d-md-none d-block'>
						<NavLink useRef={ref} items={dropDownItems} activeLink={activeLink} setActiveLink={setActiveLink} onClick={onClick} />
						<Search setRef={setRef} handleSarch={handleSarch} />
						<ListProducts items={items} />
					</div>
				</div>
			</div>
		</div>
	)
}
export default Products