import React, { useContext, useEffect, useRef } from 'react'
import { formContext } from '../../../context/formContext'
const Search = ({ handleSarch, setRef }) => {
	const { state: { product_name, category }, onChange } = useContext(formContext)
	const ref = useRef(null)
	useEffect(() => {
		const loadRef = () => {
			if (setRef && ref) {
				setRef(ref)
			}
		}
		loadRef()
	}, [setRef, ref])
	const onClick = () => {
		if (!handleSarch) {
			return
		}
		handleSarch()
	}
	return (
		<div className="input-group mb-3" ref={ref}>
			<input
				name='product_name'
				value={product_name}
				onChange={onChange}
				type="text"
				className="form-control"
				placeholder={`Pesquisar um produto ${category ? `da categoria ${category}` : ''}`}
				aria-label="product_name"
				aria-describedby="product_name" />
			<span onClick={onClick} className="input-group-text bg-success" id="product_name">
				<i className="fa fa-search text-light" aria-hidden="true"></i>
			</span>
		</div>
	)
}
export default Search