import React, { createContext, useReducer, useState } from 'react'
import { formatPhone } from '../../services/stringHandless'
export const formContext = createContext()
const initialState = {
	product_name: '',
	name: '',
	email: '',
	tel: '',
	city: '',
	uf: '',
	msg: '',
	description: '',
	image_name: '',
	hfref: '',
	is_emphasis: '',
	price: '',
	category: '',
}
const reducer = (state, { field, value }) => {
	return {
		...state,
		[field]: value,
	}
}
const FormContext = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [show, setShow] = useState(true)
	const onChange = ({ target }) => {
		const { name } = target
		dispatch({ field: name, value: target.value })
	}
	const onChangeNumber = ({ target }) => {
		const { name, value } = target
		dispatch({ field: name, value: Number(value.replace('^[0-9]*$', '')) })
	}
	const onBlurValidMinMax = ({ target }) => {
		const { name, min, max, value } = target
		if (Number(value) < Number(min)) {
			dispatch({ field: name, value: '' })
		}
		if (Number(value) > Number(max)) {
			dispatch({ field: name, value: '' })
		}
	}
	const handleForm = (field) => {
		Object.keys(field).map((key) => {
			return dispatch({ field: key, value: field[key] })
		})
	}
	const onChangeSelectInput = ({ target }) => {
		const value = JSON.parse(target.value)
		handleForm(value)
	}
	const onChangePhone = ({ target }) => {
		const { name, value } = target
		var v = formatPhone(value)
		return dispatch({ field: name, value: v })
	}
	const CEP = ({ target }) => {
		return dispatch({
			field: target.name,
			value: target.value.replace(/^(\d{5})(\d)/, '$1-$2'),
		})
	}
	const CPF = (v) => {
		v = v.replace(/\D/g, '')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		if (v.length > 14) {
			return CPF(v)
		} else {
			return v
		}
	}
	const CNPJ = (v) => {
		v = v.replace(/\D/g, '')
		v = v.replace(/^(\d{2})(\d)/, '$1.$2')
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
		v = v.replace(/(\d{4})(\d)/, '$1-$2')
		if (v.length > 18) {
			return CNPJ(v.substring(0, 18))
		} else {
			if (v.length < 15) {
				return CPF(v)
			} else {
				return v
			}
		}
	}
	return (
		<formContext.Provider
			value={{
				state,
				dispatch,
				onChange,
				onChangeNumber,
				onBlurValidMinMax,
				onChangeSelectInput,
				handleForm,
				setShow,
				show,
				onChangePhone,
				CEP,
				CNPJ,
			}}
		>
			{children}
		</formContext.Provider>
	)
}
export default FormContext
