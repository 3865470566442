import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ABOUT_TOP_PAGE,
  CONTACT_TOP_PAGE,
  IMG_BACKGROUND,
  IMG_BACKGROUND_1,
} from "../../config/flavor";
export const Masthead = ({ pathname }) => {
  const [url_background, setUrlbackground] = useState(IMG_BACKGROUND);
  const [height, setHeight] = useState("100vh");
  useEffect(() => {
    const loadBackground = () => {
      if (!pathname) {
        return;
      }
      switch (pathname) {
        case "/products":
          setUrlbackground(IMG_BACKGROUND_1);
          setHeight("10vh");
          break;
        case "/about":
          setUrlbackground(ABOUT_TOP_PAGE);
          setHeight("50vh");
          break;
        case "/contact":
          setUrlbackground(CONTACT_TOP_PAGE);
          setHeight("10vh");
          break;
        default:
          setUrlbackground(IMG_BACKGROUND);
          setHeight("100vh");
          break;
      }
    };
    loadBackground();
  }, [pathname]);
  return (
    <div className='container-fluid text-center p-0 m-0'>
      <div
        style={{
          backgroundImage: `url(${url_background})`,
          paddingTop: "10.5rem",
          paddingBottom: "6rem",
          textAlign: "center",
          color: "#fff",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: height,
        }}
      >
        {pathname === "/" && (
          <div
            className='border-0 card text-center w-100'
            style={{ backgroundColor: "transparent" }}
          >
            <div className='card-body'>
              <h1 className='card-title mb-3 fs-1 fst-italic text-light'>
                Bem vindo a RSL Plásticos!
              </h1>
              <h6 className='card-subtitle mb-2 text-light mb-3 fs-2 '>
                Tubetes e Bandejas para mudas
              </h6>
              <Link
                to='/products'
                className='btn btn-xl text-uppercase text-light mb-3'
                style={{ backgroundColor: "#a28a6b" }}
              >
                Ver produtos
              </Link>
              <div className='text-light'>
                {'"'}Brasil, um país de diversidade e encantos!{'"'}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
