import React, { useState } from "react";
import menu_items from "./menu_items.json";
import { Link, useLocation } from "react-router-dom";
export const Navigation = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <nav
        className={`p-3 navbar navbar-expand-lg navbar-light bg-dark ${
          pathname === "/products" ? "" : "sticky-top"
        }`}
      >
        <div className='container-fluid'>
          <Link className='navbar-brand' to='/'>
            <span className='me-2 fs-2' style={{ color: "#a28a6b" }}>
              RSL
            </span>
            <span className='text-success fs-2'>Plásticos</span>
          </Link>
          <button
            type='button'
            className='navbar-toggler text-light'
            onClick={() => setOpen(!open)} // Alterna o estado do collapse ao clicar no botão
            data-bs-toggle='collapse' // Atributo para indicar que o botão irá acionar um collapse
            data-bs-target='#navbarCollapse' // Atributo para definir o alvo do collapse pelo id
            aria-controls='navbarCollapse' // Atributo que descreve o elemento controlado pelo botão
            aria-expanded={open ? "true" : "false"} // Indica se o collapse está expandido ou não
          >
            <span>
              <i className='fa fa-bars' aria-hidden='true'></i>
            </span>
          </button>
          <div
            className={`collapse navbar-collapse ${open ? "show" : ""}`}
            id='navbarCollapse'
          >
            <div className='navbar-nav'></div>
            <div className='navbar-nav ms-auto'>
              {menu_items.map((item, i) => (
                <li className='nav-item' key={i}>
                  <Link
                    className='nav-link text-light fs-4'
                    onClick={() => setOpen(false)}
                    to={`${item.hfref}`}
                  >
                    {item.description}
                  </Link>
                </li>
              ))}
            </div>
          </div>
        </div>
      </nav>
      {/* <nav className="navbar navbar-expand-lg bg-dark sticky-top">
				<div className="container-fluid p-2 m-1">
					<Link className="navbar-brand" to="#">
						<span className='me-2' style={{ color: '#a28a6b' }}>
							RSL
						</span>
						<span className='text-success'>
							Plásticos
						</span>
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav">
							{menu_items.map((item, i) => (
								<li className="nav-item" key={i}>
									<Link className="nav-link text-light" to={`${item.hfref}`}>{item.description}</Link>
								</li>
							))}
						</div>
					</div>
				</div>
			</nav> */}
    </>
  );
};
