import React from 'react'
import { Link, useLocation } from 'react-router-dom'
const JumBotronAbout = () => {
	const location = useLocation()

	if (location?.pathname === '/') {
		return (
			<section className="jumbotron text-center" id="props_node_about">
				<div className='row'>
					<div className='col-md-12 col-sm-12'>
						<div className="container">
							<p className="lead text-muted">
								Somos a RSL, uma empresa especializada em fornecer tubetes e bandejas para mudas, além de oferecer todo o suporte necessário para aqueles que desejam iniciar sua própria horta. Nossa missão é tornar a prática da agricultura urbana mais acessível para todos, contribuindo para uma alimentação mais saudável e sustentável.
							</p>
						</div>
					</div>
				</div>
				<Link to="/about" className="btn btn-success my-2">
					Ler mais
				</Link>
			</section>
		)
	}
	return (
		<section className="jumbotron text-center">
			<div className='row d-flex align-items-center'>
				<div className='col-md-8 col-sm-12'>
					<div className="container">
						<p className="lead text-muted">
								Somos a RSL, uma empresa especializada em fornecer tubetes e bandejas para mudas, além de oferecer todo o suporte necessário para aqueles que desejam iniciar sua própria horta. Nossa missão é tornar a prática da agricultura urbana mais acessível para todos, contribuindo para uma alimentação mais saudável e sustentável.
						</p>
						<p className='text-secondary'>
								Nossa empresa, RSL, é especializada na venda de tubetes e bandejas para mudas. Com uma equipe dedicada e comprometida, ajudamos pessoas que desejam começar a sua própria horta. Nossa história começou com a paixão por plantas e o desejo de incentivar a prática da agricultura urbana. Ao longo dos anos, aperfeiçoamos nossos produtos e serviços para atender às necessidades e demandas de nossos clientes. Nosso objetivo é fornecer materiais de qualidade e informações úteis para aqueles que desejam cultivar suas próprias plantas e alimentos.
						</p>
					</div>
				</div>
				<div className='col-md-3 col-sm-12'>
					<div className="card" style={{ width: '18rem;' }}>
						<img src={require('../../assets/RSL/images/001.png')} className="card-img-top" alt="..." />
					</div>
				</div>
			</div>
		</section>
	)
}
export default JumBotronAbout