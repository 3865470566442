import React from 'react'
import FormContext from './context/formContext'
import { BrowserRouter } from 'react-router-dom'
import Router from './router'
import '../src/assets/icons/fontawesome-free/css/all.min.css'
import RequestContext from './context/requestContext'
function App() {
	return (
		<BrowserRouter>
			<FormContext>
				<RequestContext>
					<Router />
				</RequestContext>
			</FormContext>
		</BrowserRouter>
	)
}

export default App
